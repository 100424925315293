var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "LBLBASEINFO" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable && !_vm.disabled && _vm.isOld,
                            expression: "editable&&!disabled&&isOld",
                          },
                        ],
                        attrs: {
                          url: _vm.completeUrl,
                          isSubmit: _vm.isComplete,
                          param: _vm.check,
                          mappingType: "PUT",
                          label: "LBLCOMPLETE",
                          icon: "check",
                        },
                        on: {
                          beforeAction: _vm.completeInfo,
                          btnCallback: _vm.completeCallback,
                        },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable && !_vm.disabled,
                            expression: "editable&&!disabled",
                          },
                        ],
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.check,
                          mappingType: _vm.mappingType,
                          label: "LBLSAVEALL",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveInfo,
                          btnCallback: _vm.saveCallback,
                        },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable && !_vm.disabled && _vm.isOld,
                            expression: "editable&&!disabled&&isOld",
                          },
                        ],
                        attrs: { label: "LBLREMOVE", icon: "delete" },
                        on: { btnClicked: _vm.deleteInfo },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                  [
                    _c("c-text", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        label: "점검명",
                        name: "checkName",
                      },
                      model: {
                        value: _vm.check.checkName,
                        callback: function ($$v) {
                          _vm.$set(_vm.check, "checkName", $$v)
                        },
                        expression: "check.checkName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        default: "today",
                        label: "점검일",
                        name: "checkDate",
                      },
                      model: {
                        value: _vm.check.checkDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.check, "checkDate", $$v)
                        },
                        expression: "check.checkDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
                  [
                    _c("c-field", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        data: _vm.check,
                        deptValue: "checkerDeptCd",
                        type: "dept_user",
                        label: "점검자",
                        name: "checkerId",
                      },
                      model: {
                        value: _vm.check.checkerId,
                        callback: function ($$v) {
                          _vm.$set(_vm.check, "checkerId", $$v)
                        },
                        expression: "check.checkerId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
                  [
                    _c("c-plant", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        type: "edit",
                        name: "plantCd",
                      },
                      model: {
                        value: _vm.check.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.check, "plantCd", $$v)
                        },
                        expression: "check.plantCd",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "건축물 정보" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-4 col-lg-4" },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: true,
                        editable: _vm.editable,
                        label: "상호",
                        name: "checkName",
                      },
                      model: {
                        value: _vm.company.companyName,
                        callback: function ($$v) {
                          _vm.$set(_vm.company, "companyName", $$v)
                        },
                        expression: "company.companyName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-select", {
                      attrs: {
                        disabled: true,
                        editable: _vm.editable,
                        codeGroupCd: "FIRE_MNG_GRADE_CD",
                        label: "소방안전관리대상물 등급",
                        itemText: "codeName",
                        itemValue: "code",
                        name: "fireMngGradeCd",
                        type: "edit",
                      },
                      model: {
                        value: _vm.company.fireMngGradeCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.company, "fireMngGradeCd", $$v)
                        },
                        expression: "company.fireMngGradeCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: true,
                        editable: _vm.editable,
                        label: "소재지",
                        name: "addr",
                      },
                      model: {
                        value: _vm.company.addr,
                        callback: function ($$v) {
                          _vm.$set(_vm.company, "addr", $$v)
                        },
                        expression: "company.addr",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: true,
                        editable: _vm.editable,
                        label: "지하층",
                        name: "underFloor",
                      },
                      model: {
                        value: _vm.company.underFloor,
                        callback: function ($$v) {
                          _vm.$set(_vm.company, "underFloor", $$v)
                        },
                        expression: "company.underFloor",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: true,
                        editable: _vm.editable,
                        label: "지상층",
                        name: "upFloor",
                      },
                      model: {
                        value: _vm.company.upFloor,
                        callback: function ($$v) {
                          _vm.$set(_vm.company, "upFloor", $$v)
                        },
                        expression: "company.upFloor",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: true,
                        editable: _vm.editable,
                        label: "연면적(㎡)",
                        name: "totalFloorArea",
                      },
                      model: {
                        value: _vm.company.totalFloorArea,
                        callback: function ($$v) {
                          _vm.$set(_vm.company, "totalFloorArea", $$v)
                        },
                        expression: "company.totalFloorArea",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: true,
                        editable: _vm.editable,
                        label: "바닥면적(㎡)",
                        name: "floorArea",
                      },
                      model: {
                        value: _vm.company.floorArea,
                        callback: function ($$v) {
                          _vm.$set(_vm.company, "floorArea", $$v)
                        },
                        expression: "company.floorArea",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: true,
                        editable: _vm.editable,
                        label: "동수",
                        name: "buildCount",
                      },
                      model: {
                        value: _vm.company.buildCount,
                        callback: function ($$v) {
                          _vm.$set(_vm.company, "buildCount", $$v)
                        },
                        expression: "company.buildCount",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
              [
                _c(
                  "c-table",
                  {
                    ref: "table1",
                    staticClass: "q-mt-sm",
                    attrs: {
                      title: "점검 대상",
                      columns: _vm.grid.columns,
                      data: _vm.check.targets,
                      gridHeight: _vm.gridHeight,
                      editable: _vm.editable && !_vm.disabled,
                      filtering: false,
                      columnSetting: false,
                      isExcelDown: false,
                      selection: "multiple",
                      rowKey: "sopFireFightingCheckTargetId",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable && !_vm.disabled
                              ? _c("c-btn", {
                                  attrs: { label: "LBLEXCEPT", icon: "delete" },
                                  on: { btnClicked: _vm.deleteTarget },
                                })
                              : _vm._e(),
                            _vm.editable && !_vm.disabled
                              ? _c("c-btn", {
                                  attrs: { label: "LBLADD", icon: "add" },
                                  on: { btnClicked: _vm.addTarget },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
              [
                _c("c-table", {
                  ref: "table2",
                  staticClass: "q-mt-sm",
                  attrs: {
                    title: "점검항목",
                    columns: _vm.gridResult.columns,
                    data: _vm.check.results,
                    gridHeight: _vm.gridHeight,
                    editable: _vm.editable && !_vm.disabled,
                    filtering: false,
                    columnSetting: false,
                    isExcelDown: false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customArea",
                      fn: function ({ props, col }) {
                        return [
                          col.name === "customCol"
                            ? [
                                _c(_vm.imprComponent, {
                                  tag: "component",
                                  attrs: {
                                    col: col,
                                    props: props,
                                    inputEditable:
                                      _vm.editable && !_vm.disabled,
                                    isImmShow: true,
                                    requestContentsCols:
                                      _vm.requestContentsCols,
                                    tableKey:
                                      "sopFireFightingCheckTargetResultId",
                                    ibmTaskTypeCd: "ITT0000180",
                                    ibmTaskUnderTypeCd: "ITTU000210",
                                  },
                                  on: { imprChange: _vm.imprChange },
                                }),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "불량사항 개선보고" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        label: "보고일시",
                        name: "reportDate",
                      },
                      model: {
                        value: _vm.check.reportDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.check, "reportDate", $$v)
                        },
                        expression: "check.reportDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-field", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        data: _vm.check,
                        deptValue: "receivedReportDeptCd",
                        type: "dept_user",
                        label: "확인자",
                        name: "receivedReportId",
                      },
                      model: {
                        value: _vm.check.receivedReportId,
                        callback: function ($$v) {
                          _vm.$set(_vm.check, "receivedReportId", $$v)
                        },
                        expression: "check.receivedReportId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        isArray: false,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        codeGroupCd: "SOP_REPORT_METHOD_CD",
                        valueText: "codeName",
                        valueKey: "code",
                        label: "보고방법",
                        name: "sopReportMethodCd",
                      },
                      model: {
                        value: _vm.check.sopReportMethodCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.check, "sopReportMethodCd", $$v)
                        },
                        expression: "check.sopReportMethodCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        isArray: false,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        codeGroupCd: "SOP_ACTION_METHOD_CD",
                        valueText: "codeName",
                        valueKey: "code",
                        label: "조치방법",
                        name: "sopActionMethodCd",
                      },
                      model: {
                        value: _vm.check.sopActionMethodCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.check, "sopActionMethodCd", $$v)
                        },
                        expression: "check.sopActionMethodCd",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }