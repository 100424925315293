<template>
  <div>
    <q-form ref="editForm">
      <c-card title="LBLBASEINFO" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-show="editable&&!disabled&&isOld"
              :url="completeUrl"
              :isSubmit="isComplete"
              :param="check"
              mappingType="PUT"
              label="LBLCOMPLETE"
              icon="check"
              @beforeAction="completeInfo"
              @btnCallback="completeCallback" />
            <c-btn
              v-show="editable&&!disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="check"
              :mappingType="mappingType"
              label="LBLSAVEALL"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
            <c-btn
              v-show="editable&&!disabled&&isOld"
              label="LBLREMOVE"
              icon="delete"
              @btnClicked="deleteInfo" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-text
              :required="true"
              :disabled="disabled"
              :editable="editable"
              label="점검명"
              name="checkName"
              v-model="check.checkName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-datepicker
              :required="true"
              :disabled="disabled"
              :editable="editable"
              default="today"
              label="점검일"
              name="checkDate"
              v-model="check.checkDate"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-field
              :required="true"
              :disabled="disabled"
              :editable="editable"
              :data="check"
              deptValue="checkerDeptCd"
              type="dept_user"
              label="점검자"
              name="checkerId"
              v-model="check.checkerId"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-plant
              :required="true"
              :disabled="disabled"
              :editable="editable"
              type="edit"
              name="plantCd"
              v-model="check.plantCd" />
          </div>
        </template>
      </c-card>
      <c-card title="건축물 정보" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
            <c-text
              :disabled="true"
              :editable="editable"
              label="상호"
              name="checkName"
              v-model="company.companyName">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-select
              :disabled="true"
              :editable="editable"
              codeGroupCd="FIRE_MNG_GRADE_CD"
              label="소방안전관리대상물 등급"
              itemText="codeName"
              itemValue="code"
              name="fireMngGradeCd"
              type="edit"
              v-model="company.fireMngGradeCd">
            </c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-text
              :disabled="true"
              :editable="editable"
              label="소재지"
              name="addr"
              v-model="company.addr">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-text
              :disabled="true"
              :editable="editable"
              label="지하층"
              name="underFloor"
              v-model="company.underFloor">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-text
              :disabled="true"
              :editable="editable"
              label="지상층"
              name="upFloor"
              v-model="company.upFloor">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-text
              :disabled="true"
              :editable="editable"
              label="연면적(㎡)"
              name="totalFloorArea"
              v-model="company.totalFloorArea">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-text
              :disabled="true"
              :editable="editable"
              label="바닥면적(㎡)"
              name="floorArea"
              v-model="company.floorArea">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-text
              :disabled="true"
              :editable="editable"
              label="동수"
              name="buildCount"
              v-model="company.buildCount">
            </c-text>
          </div>
        </template>
      </c-card>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="table1"
          class="q-mt-sm"
          title="점검 대상"
          :columns="grid.columns"
          :data="check.targets"
          :gridHeight="gridHeight"
          :editable="editable&&!disabled"
          :filtering="false"
          :columnSetting="false"
          :isExcelDown="false"
          selection="multiple"
          rowKey="sopFireFightingCheckTargetId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable&&!disabled" label="LBLEXCEPT" icon="delete" @btnClicked="deleteTarget" />
              <c-btn v-if="editable&&!disabled" label="LBLADD" icon="add" @btnClicked="addTarget" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <c-table
          ref="table2"
          class="q-mt-sm"
          title="점검항목"
          :columns="gridResult.columns"
          :data="check.results"
          :gridHeight="gridHeight"
          :editable="editable&&!disabled"
          :filtering="false"
          :columnSetting="false"
          :isExcelDown="false"
        >
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='customCol'">
              <component
                :is="imprComponent"
                :col="col"
                :props="props"
                :inputEditable="editable&&!disabled"
                :isImmShow="true"
                :requestContentsCols="requestContentsCols"
                tableKey="sopFireFightingCheckTargetResultId"
                ibmTaskTypeCd="ITT0000180"
                ibmTaskUnderTypeCd="ITTU000210"
                @imprChange="imprChange"
              />
            </template>
          </template>
        </c-table>
      </div>
    </div>
      <c-card title="불량사항 개선보고" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-datepicker
              :disabled="disabled"
              :editable="editable"
              label="보고일시"
              name="reportDate"
              v-model="check.reportDate">
            </c-datepicker>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-field
              :required="true"
              :disabled="disabled"
              :editable="editable"
              :data="check"
              deptValue="receivedReportDeptCd"
              type="dept_user"
              label="확인자"
              name="receivedReportId"
              v-model="check.receivedReportId"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <c-checkbox
              :isArray="false"
              :disabled="disabled"
              :editable="editable"
              codeGroupCd="SOP_REPORT_METHOD_CD"
              valueText="codeName"
              valueKey="code"
              label="보고방법"
              name="sopReportMethodCd"
              v-model="check.sopReportMethodCd"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <c-checkbox
              :isArray="false"
              :disabled="disabled"
              :editable="editable"
              codeGroupCd="SOP_ACTION_METHOD_CD"
              valueText="codeName"
              valueKey="code"
              label="조치방법"
              name="sopActionMethodCd"
              v-model="check.sopActionMethodCd"
            />
          </div>
        </template>
      </c-card>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingCheckInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingCheckId: '',
      }),
    },
    check: {
      type: Object,
      default: () => ({
        sopFireFightingCheckId: '',  // 소방설비 점검 일련번호
        plantCd: '',  // 사업장
        checkName: '',  // 점검명
        sopFireFightingCheckStepCd: '',  // 점검진행상태
        checkDate: '',  // 점검일
        checkerId: '',  // 수행자
        reportDate: '',  // 보고일시
        sopReportMethodCd: '',  // 보고방법
        receivedReportId: '',  // 보고받은 사람
        sopActionMethodCd: '',  // 조치방법
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        targets: [],
        deleteTargets: [],
        results: [],
        deleteResults: [],
        imprs: [],
      }),
    },
    company: {
      type: Object,
      default: () => ({
        mdmCompanyId: '',  // 회사 일련번호
        companyName: '',  // 회사명(상호)
        bizNo: '',  // 사업자번호
        managerName: '',  // 대표자
        addr: '',  // 소재지
        underFloor: '',  // 지하층
        upFloor: '',  // 지상층
        totalFloorArea: '',  // 연면적
        floorArea: '',  // 바닥면적
        buildCount: '',  // 동수
        fireMngGradeCd: null,  // 소방안전관리대상물 등급
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'sopFireFightingTypeName',
            field: 'sopFireFightingTypeName',
            label: '설비종류',
            align: 'center',
            sortable: false,
            style: 'width: 130px',
          },
          {
            name: 'fireFightingName',
            field: 'fireFightingName',
            label: '소방설비',
            align: 'center',
            sortable: false,
            style: 'width: 130px',
          },
        ],
      },
      gridResult: {
        columns: [
          {
            name: 'itemName',
            field: 'itemName',
            label: '점검항목',
            align: 'left',
            style: 'width:350px',
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '확인내용',
            align: 'left',
            style: 'width:350px',
            sortable: false,
            type: 'textarea'
          },
          {
            name: 'sopFireFightingCheckResultCd',
            field: 'sopFireFightingCheckResultCd',
            label: '확인결과',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'select',
            codeGroupCd: 'SOP_FIRE_FIGHTING_CHECK_RESULT_CD',
            value: '',
            setHeader: true,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선/진행상태',
            align: 'center',
            type: 'custom',
            sortable: false
          },
        ],
      },
      editable: false,
      isSave: false,
      isComplete: false,
      saveUrl: '',
      deleteUrl: '',
      completeUrl: '',
      mappingType: 'PUT',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      selectedRow: {},
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      // let height = this.contentHeight - 250
      // if (height < 500) {
      //   height = 500;
      // }
      // return String(height) + 'px';
      return '300px';
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['itemName'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.fft.fireFightingCheck.update.url
      this.deleteUrl = transactionConfig.fft.fireFightingCheck.delete.url
      this.completeUrl = transactionConfig.fft.fireFightingCheck.complete.url
      // code setting
      // list setting
    },
    deleteTarget() {
      let selectData = this.$refs['table1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.check.deleteTargets) this.check.deleteTargets = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.check.deleteTargets, { sopFireFightingId: item.sopFireFightingId }) === -1) {
              this.check.deleteTargets.push(item)
            }
          this.check.targets = this.$_.reject(this.check.targets, item);
        })
        this.$refs['table1'].$refs['compo-table'].clearSelection();
        this.selectedRow = {};
      }
    },
    addTarget() {
      this.popupOptions.title = '소방설비 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.check.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/fft/fireFightingPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeFireFightingPopup;
    },
    closeFireFightingPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.check.targets, { sopFireFightingId: item.sopFireFightingId }) === -1) {
            this.check.targets.splice(0, 0, { 
              sopFireFightingCheckTargetId: uid(),  // 소방설비 점검대상 일련번호
              sopFireFightingCheckId: this.popupParam.sopFireFightingCheckId,  // 소방설비 점검 일련번호
              sopFireFightingId: item.sopFireFightingId,  // 소방설비 일련번호
              fireFightingName: item.fireFightingName,  // 소방설비 일련번호
              sopFireFightingTypeName: item.sopFireFightingTypeName,  // 소방설비 일련번호
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C'
            })
          }
        })
      }
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.fft.fireFightingCheck.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.fft.fireFightingCheck.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.check.regUserId = this.$store.getters.user.userId;
              this.check.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'sopFireFightingCheckId', result.data)
      this.$emit('getDetail');
    },
    deleteInfo() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopFireFightingCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeInfo() {
      /**
       * 1. 점검대상이 하나 이상있는지?
       * 2. 점검대상에 점검일이 입력되어 있는지?
       */
      if (!this.check.targets || this.check.targets.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '점검대상이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
        return;
      } 
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGCOMPLETE', // 완료하시겠습니까?,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.check.regUserId = this.$store.getters.user.userId;
              this.check.chgUserId = this.$store.getters.user.userId;

              this.isComplete = !this.isComplete;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'sopFireFightingCheckId', result.data)
      this.$emit('getDetail');
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table2'].$refs['compo-table'].resetVirtualScroll();
      this.$emit('getDetail');
    },
  }
};
</script>